@charset "utf-8";

@import url(//fonts.googleapis.com/css?family=Montserrat:700);
@import url(//fonts.googleapis.com/css?family=Inconsolata:400,700);

// Our variables
$base-font-family: Inconsolata, Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #222;
$background-color: #aaa;
$brand-color:      #ff7777;

$grey-color:       #ff0000;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 15%);

$on-palm:          600px;
$on-laptop:        800px;

// Using media queries with like this:
// @include media-query($palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;


/*h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
  margin: 0 0 0.5rem -0.1rem;
  line-height: 1;
  text-rendering: optimizeLegibility;
}*/

body {
  background:url('/img/circuit.png') no-repeat center bottom fixed;
}

.site-footer a,
h1 a,
h1 a:visited,
h2 a,
h2 a:visited,
h3 a,
h3 a:visited,
h4 a,
h4 a:visited {
  color: $text-color;
}

article.Projects h1 a,
article.Projects h1 a:visited,
article.Projects h2 a,
article.Projects h2 a:visited,
article.Projects h3 a,
article.Projects h3 a:visited,
article.Projects h4 a,
article.Projects h4 a:visited {
  color: darken($brand-color, 15%);;
}

#face {
float:right;
margin:0 0 1em 1em;
}

@media (max-width:475px) {
  #face {
    display:block;
    float:none;
    margin:0 auto;
  }
}